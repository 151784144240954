import React, { useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import DialogTitle from '@common/Dialog/DialogTitle';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import { setupGdapM365Tenant } from '@services/requests/integration';
import { requiredValidator } from '@utils/validators';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const SetupGDAPDialog = ({ open = true, onClose, crmId }) => {
  const [processing, setProcessing] = useState(false);

  const sharedClasses = useSharedStyles();

  const submit = values => {
    setProcessing(true);
    setupGdapM365Tenant({
      ...values,
      redirectUrl: window.location.href.split('?')[0],
      crmId,
    })
      .then(({ data }) => {
        window.location.href = data;
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => ({
    domainName: requiredValidator(values.domainName),
    applicationId: requiredValidator(values.applicationId),
    applicationSecret: requiredValidator(values.applicationSecret),
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Connect to Microsoft 365 (GDAP)</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Domain"
                contentSize={12}
                labelSize={12}
                content={
                  <Field
                    placeholder="Domain"
                    name="domainName"
                    id="domainName"
                    render={Input}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Application ID"
                contentSize={12}
                labelSize={12}
                content={
                  <Field
                    placeholder="Application ID"
                    name="applicationId"
                    id="applicationId"
                    render={Input}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Application Secret"
                contentSize={12}
                labelSize={12}
                content={
                  <Field
                    placeholder="Application Secret"
                    name="applicationSecret"
                    id="applicationSecret"
                    render={Input}
                    disabled={processing}
                    type="password"
                  />
                }
              />

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={onClose}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  loading={processing}
                  disabled={processing}
                  type="submit"
                  viewType="blue"
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

SetupGDAPDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  crmId: PropTypes.string.isRequired,
};

export default SetupGDAPDialog;
