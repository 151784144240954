import React, { useState } from 'react';
import { useListContext } from 'react-admin';

import ViewAutoComplete from '@ui/components/common/ViewAutoComplete/ViewAutoComplete';
import Input from '@ui/components/Auth/Common/Input';

import { regionsAutocomplete } from '@constants/regions';
import { searchTypesAutocomplete } from '../constants';

import useStyles from '../styles';

const MAX_PHONE_SEARCH_LIMIT = 5;

const PurchaseFilters = () => {
  const [region, setRegion] = useState(regionsAutocomplete[0]);
  const [searchType, setSearchType] = useState(searchTypesAutocomplete[1]);
  const [searchValue, setSearchValue] = useState();

  const classes = useStyles();

  const { setFilters, filterValues } = useListContext();

  const handleChange = (item, setter, filterName) => {
    let newValue = item?.value;
    if (newValue?.length > MAX_PHONE_SEARCH_LIMIT) {
      newValue = newValue.substring(0, MAX_PHONE_SEARCH_LIMIT);
      setter({ value: newValue });
    } else {
      setter({ value: newValue });
      setFilters({ [filterName]: newValue }, filterValues, true);
    }
  };

  return (
    <div className={classes.filterContainer}>
      <ViewAutoComplete
        options={regionsAutocomplete}
        onChange={item => {
          handleChange(item, setRegion, 'region');
        }}
        currentView={region}
        textFieldLabel="Region"
      />
      <ViewAutoComplete
        options={searchTypesAutocomplete}
        onChange={item => {
          handleChange(item, setSearchType, 'searchType');
        }}
        currentView={searchType}
        textFieldLabel="Search Type"
      />

      <Input
        meta={{ touched: false }}
        input={{
          ...searchValue,
          onChange: e => {
            handleChange({ value: e.target.value }, setSearchValue, 'search');
          },
        }}
        placeholder="Search"
      />
    </div>
  );
};

export default PurchaseFilters;
