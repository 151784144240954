import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Input,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import cx from 'classnames';
import {
  ExpandMoreOutlined,
  Search as SearchIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { cloneDeep } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { currentUserDataSelector } from '@store/selectors';
import localStorage from '@services/localStorage';
import localStorageConst from '@constants/localStorage';

import { sidebarContent } from '@components/Layout/SideBar/sideBarItems';
import useStyles from './styles';

const MIN_SEARCH_TEXT_LENGTH = 2;

const SideBar = React.memo(() => {
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [searchText, setSearchText] = useState('');

  const { push } = useHistory();
  const sidebarOpen = useSelector(state => state.admin.ui.sidebarOpen);
  const location = useLocation();
  const classes = useStyles({ sidebarOpen });

  const currentUser = useSelector(currentUserDataSelector);

  const sideBarItems = useMemo(() => {
    const allowedResources = localStorage.tryGetItem(
      localStorageConst.ALLOWED_RESOURCES,
    );

    // there are no need to all check if user data isn't loaded
    if (!currentUser) return [];

    return cloneDeep(sidebarContent).filter(i => {
      // checking if item have enough permissions
      if (
        !i?.permissions.includes(currentUser?.roleId) ||
        !currentUser?.roleId
      ) {
        return false;
      }

      // checking tenant resources for sidebar items
      if (
        i.dependencies.length &&
        !i?.dependencies?.some(dep => allowedResources.includes(dep))
      ) {
        return false;
      }

      // checking if item have items or not because we have sidebar items without sub elements, and they need to filtered by dependencies and resources
      if (!i?.items) {
        return true;
      }

      // checking permissions for subitems
      if (i?.items) {
        // eslint-disable-next-line no-param-reassign
        i.items = i?.items
          // filtering sub items by role permissions
          .filter(subItem => {
            if (!subItem?.permissions) {
              return true;
            }
            return !!subItem?.permissions?.includes(currentUser?.roleId);
          })
          // filtering sub items by tenant dependencies
          .filter(subItem => {
            // debugger;
            // checking tenant resources for sidebar items
            if (subItem?.dependencies.length) {
              return subItem?.dependencies.some(dep =>
                allowedResources.includes(dep),
              );
            }
            return true;
          })
          // sorting sub items alphabetically
          .sort((a, b) => a.label.localeCompare(b.label));
      }

      // checking if item have items after filtering
      if (i?.items?.length === 0) {
        return false;
      }

      return true;
    });
  }, [currentUser]);

  const handleOpened = label => {
    if (expandedPanels.includes(label)) {
      setExpandedPanels(panels => panels.filter(i => i !== label));
    } else {
      setExpandedPanels(panels => [...panels, label]);
    }
  };

  const handleSearch = text => {
    setSearchText(text);
  };

  const dropSearch = () => {
    if (searchText !== '') {
      handleSearch('');
    }
  };

  const handleFilteringAfterSearch = i => {
    if (searchText.length < MIN_SEARCH_TEXT_LENGTH) {
      return true;
    }

    // filtering sidebar items without sub items
    if (!i.items && i.label.toLowerCase().includes(searchText)) {
      return true;
    }

    if (i.items) {
      const subItems = i.items.filter(subItem => {
        return subItem.label.toLowerCase().includes(searchText);
      });
      if (subItems.length > 0) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className={classes.sideBar} style={{ position: 'relative' }}>
      <div className={cx(classes.sideBarSearch)}>
        {sidebarOpen && (
          <Input
            autoComplete="off"
            id="input"
            type="text"
            fullWidth
            placeholder="Search"
            className={classes.sideBarSearchInput}
            inputProps={{ 'aria-label': 'naked' }}
            endAdornment={
              <label htmlFor="input">
                <InputAdornment position="start">
                  {searchText !== '' ? (
                    <CloseIcon
                      className={classes.sideBarSearchIcon}
                      onClick={dropSearch}
                    />
                  ) : (
                    <SearchIcon className={classes.sideBarSearchIcon} />
                  )}
                </InputAdornment>
              </label>
            }
            onChange={e => {
              handleSearch(e.target.value.toLowerCase());
            }}
            value={searchText}
          />
        )}
      </div>

      {sideBarItems.filter(handleFilteringAfterSearch).map(item => {
        return (
          <>
            {item?.items ? (
              <Accordion
                className={cx(classes.accordion, {
                  [classes.expandedAccordion]:
                    expandedPanels.includes(item?.label) ||
                    searchText?.length >= MIN_SEARCH_TEXT_LENGTH,
                  [classes.activeItem]: item?.items.some(
                    subItem => subItem.path === location.pathname,
                  ),
                })}
                TransitionProps={{ unmountOnExit: true }}
                onChange={() => {
                  handleOpened(item?.label);
                }}
                expanded={
                  expandedPanels.includes(item?.label) ||
                  searchText?.length >= MIN_SEARCH_TEXT_LENGTH
                }
              >
                <AccordionSummary>
                  <div className={classes.sidebarItem}>
                    <Tooltip
                      title={!sidebarOpen ? item?.label : ''}
                      placement="right"
                    >
                      {item.icon}
                    </Tooltip>

                    <div style={{ fontWeight: 'bold' }}>
                      {sidebarOpen && item?.label}
                    </div>
                    <div
                      style={{
                        marginLeft: 'auto',
                        transform: expandedPanels.includes(item?.label)
                          ? 'rotate(180deg)'
                          : 'unset',
                      }}
                    >
                      <ExpandMoreOutlined
                        style={{
                          translate: expandedPanels.includes(item?.label)
                            ? 'rotate(0deg)'
                            : 'rotate(90deg',
                        }}
                      />
                    </div>
                  </div>
                </AccordionSummary>

                <AccordionDetails
                  style={{
                    padding: '0px',
                    display: 'flex',
                    gap: '5px',
                    flexDirection: 'column',
                  }}
                >
                  {item.items
                    .filter(i => {
                      if (searchText.length < MIN_SEARCH_TEXT_LENGTH) {
                        return true;
                      }
                      return i.label.toLowerCase().includes(searchText);
                    })
                    .map(subItem => (
                      <Link
                        key={subItem.label}
                        to={subItem.path}
                        className={cx(
                          classes.sidebarItem,
                          classes.subItem,
                          classes.hoverAccordion,
                          {
                            [classes.activeItem]:
                              location.pathname === subItem?.path,
                          },
                        )}
                      >
                        <Tooltip
                          title={!sidebarOpen ? subItem?.label : ''}
                          placement="right"
                        >
                          {subItem?.icon}
                        </Tooltip>
                        {sidebarOpen && <div>{subItem?.label}</div>}
                      </Link>
                    ))}
                </AccordionDetails>
              </Accordion>
            ) : (
              <Link
                className={cx(classes.sidebarItem, classes.hoverAccordion, {
                  [classes.activeItem]: location.pathname === item?.path,
                })}
                to={item?.path}
              >
                <Tooltip
                  title={!sidebarOpen ? item?.label : ''}
                  placement="right"
                >
                  {item?.icon}
                </Tooltip>
                <div style={{ fontWeight: 'bold' }}>{item?.label}</div>
              </Link>
            )}
          </>
        );
      })}
    </div>
  );
});

export default SideBar;
