import { makeStyles } from '@material-ui/core';
import { defaultPalette, getPalette } from '@services/themes/mainTheme';

const useStyles = makeStyles(theme => {
  const palette = getPalette() || defaultPalette;
  return {
    sideBar: {
      width: '100%',
      height: '100%',
      overflowX: 'clip',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      paddingTop: '15px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    sideBarItem: {
      cursor: 'pointer !important',
      color: theme.palette.menuItem.color,
      width: '100%',
      maxWidth: props => (props.sidebarOpen ? '16rem' : '3.4375rem'),
      boxSizing: 'border-box',
      padding: '5px 10px',
      transition: 'color 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      '&:hover': {
        background: 'red !important',
      },
      '& svg': {
        minWidth: '20px',
        minHeight: '20px',
        marginRight: '26px',
        marginLeft: '16px',
        '& path': {
          stroke: theme.palette.menuItem.icon,
        },
        '& circle': {
          stroke: theme.palette.menuItem.icon,
        },
      },
    },
    mainItem: {
      width: '255px',
      position: 'relative',
    },
    sideBarItemText: {
      '& svg:first-child': { position: 'absolute' },
      margin: '1rem 0',
      display: 'flex',
      alignItems: 'center',
      '& span': {
        marginLeft: '65px',
        width: '150px',
        textAlign: 'left',
      },
    },
    activeItem: {
      background: 'rgba(255,255,255,0.24)',
      borderRight: `3px solid ${palette.sideBarSelected}`,
      transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    activeItemFlag: {
      width: '5px',
      height: '55px',
      background: palette.sideBarSelected,
      position: 'absolute',
      right: 0,
    },
    displayElement: {
      opacity: 1,
      transition:
        'opacity 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) !important',
    },
    hideElement: {
      opacity: '0 !Important',
      transition:
        'opacity 0.001s cubic-bezier(0.68, -0.55, 0.27, 1.55)  !important',
    },
    firstListItem: {
      marginTop: '0.5rem',
    },
    subMenu: {
      width: '87%',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '25px',
      transition: 'margin-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    slideInAnimation: {
      height: '0px',
      transition: 'height 5s',
    },
    slideOutAnimation: {
      height: 'auto',
      transition: 'height 5s',
    },
    alignLeft: {
      color: 'transparent',
      marginLeft: '0px',
    },
    sideBarItemClosed: {
      color: 'transparent',
      transition: 'color 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      '& svg': {
        marginRight: '26px',
        marginLeft: '16px',
      },
    },
    arrowIcon: {
      position: 'absolute',
      right: 0,
    },
    relative: {
      position: 'relative',
    },
    openIcon: {
      transform: 'rotate(0deg)',
    },
    downIcon: {
      transform: 'rotate(270deg)',
    },
    sideBarSearch: {
      position: 'sticky',
      zIndex: '1111',
      background: palette.sideBarBackground,
      justifyContent: 'center',
      alignItems: 'center',
      opacity: props => (props.sidebarOpen ? '1' : '0'),
      display: props => (props.sidebarOpen ? 'flex' : 'hidden'),
      width: props => (props.sidebarOpen ? '16rem' : '0rem'),
      color: props =>
        props.sidebarOpen ? theme.palette.menuItem.color : 'transparent',
      transition:
        'opacity 0.3s cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 0.3s cubic-bezier(0.4, 0, 0.6, 1) 0ms, color 0.3s',
    },
    sideBarSearchInput: {
      padding: '10px',
      color: theme.palette.menuItem.color,
      '&::before': {
        display: 'none',
      },
      '&::after': {
        borderBottomColor: theme.palette.menuItem.color,
      },
    },
    sideBarSearchIcon: {
      color: theme.palette.menuItem.icon,
      cursor: 'pointer',
    },
    sidebarItem: {
      width: props => (props.sidebarOpen ? '16rem' : '3.4375rem'),
      padding: '10px 10px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      color: theme.palette.menuItem.color,
      margin: '0px',
      '& > div': {
        display: props => (props.sidebarOpen ? 'flex' : 'none'),
        alignItems: 'center',
      },
      '& svg': {
        width: '28px',
        height: '28px',
        '& path': {
          fill: theme.palette.menuItem.icon,
        },
      },
      '& a': {
        color: theme.palette.menuItem.color,
        textDecoration: 'none',
        display: 'flex',
        gap: '10px',
        '& svg': {
          width: '28px',
          height: '28px',
          '& path': {
            fill: theme.palette.menuItem.icon,
          },
        },
        '& > div': {
          display: props => (props.sidebarOpen ? 'flex' : 'none'),
          alignItems: 'center',
        },
      },
    },
    subItem: {
      paddingLeft: props => (props.sidebarOpen ? '25px' : 'none'),
      transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    accordion: {
      background: 'transparent',
      width: props => (props.sidebarOpen ? '16rem' : '3.4375rem'),
      boxSizing: 'border-box',
      boxShadow: 'none',
      transition: 'width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      overflow: 'clip',
      '& > div': {
        padding: '0px',
        width: props => (props.sidebarOpen ? '16rem' : '3.4375rem'),
      },
      '&:hover': {
        background: '#ffffff1a',
      },
    },
    hoverAccordion: {
      cursor: 'pointer',
      '&:hover': {
        background: '#FFFFFF5B',
      },
    },
    expandedAccordion: {
      margin: '0px !important',
      '& .MuiAccordionSummary-content': {
        margin: '12px 0px !important',
      },
    },
  };
});

export default useStyles;
