import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  center: {
    width: '100%',
    textAlign: 'center',
  },
  button: {
    margin: '0 8px 8px 0',
  },
}));

export default useStyles;
